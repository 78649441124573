import React, {useEffect, useState} from 'react'
import CoinMarket from './CoinMarket';
import WalletCards from '../Wallet/WalletCards';

import Cards from '../Cards/Cards';
import MobileMenu from './MobileMenu';
import NewCard from '../Cards/NewCard';
import { Divider } from '@mui/material';
import BalanceCard from '../Cards/BalanceCard';
import EarningAnalysis from '../Earning/EarningAnalysis';

import { Card, CardContent, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { yellow } from '@mui/material/colors';

const Index = () => {

  const appMode = useSelector((state) => state.mode.mode);
  const user = useSelector((state) => state.user.user);
  const [ip, setIP] = useState("192.168.23.5");
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

  const getData = async () => {
    fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => setIP(data.ip))
    .catch(error => console.log(error))
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
    {/* <NewCard/> */}
    <MobileMenu/>
    {/* <EarningAnalysis/> */}
    <Card sx={{ bgcolor: '#36492d'}}>
    
      {appMode === 'dark'? 
            <Box sx={{display: 'flex', justifyContent: 'space-between', paddingY: '10px', paddingX: '18px', height: '20px',  marginBottom: '28px', position: 'relative', zIndex: 1}}>

            <Box variant="button" sx={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>

             <Typography color={user.verification_status === 'unverified'? '#ffbc05' : '#79d92b'}>{user.verification_status} user</Typography> 
              
              <Typography >{user.verification_status === 'unverified'? <NewReleasesIcon style={{color: '#fa9f02'}}/>: <VerifiedIcon color='success'/> }</Typography>
              
            </Box>
            
            <Typography variant="body2">
              Login Ip: {ip}
            </Typography>
            </Box>
            :
            <Box sx={{display: 'flex', justifyContent: 'space-between', paddingY: '10px', paddingX: '18px', height: '20px',  marginBottom: '28px', position: 'relative', zIndex: 1}}>
            <Box variant="button" sx={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>

              <Typography color={user.verification_status === 'unverified'? '#ffbc05' : '#2ea105'}>{user.verification_status} user</Typography> 
              
              <Typography >{user.verification_status === 'unverified'? <NewReleasesIcon style={{color: '#fa9f02'}}/>: <VerifiedIcon color='success'/> }</Typography>
              
              </Box>
            <Typography variant="body2">
              Login Ip: {ip}
            </Typography>
            </Box>
            }
    </Card> 
    <WalletCards/>
    
    <Divider/>
    <CoinMarket/>
    </>
  )
}

export default Index
