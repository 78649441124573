import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery } from 'react-query';
import Avatar from '@mui/material/Avatar';
import apiClient from '../../../request/http-common';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import { useMutation } from "react-query";


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserWithdrawals(props) {

  const formatPrice = (amount, currency)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'usd', currencySign: 'standard' }).format(amount);

      const {withdrawals} = props;
      const [open2, setOpen2] = React.useState(false);
      const [withdrawalId, setwithdrawalId] = useState('');
      const [withdrawalStatus, setwithdrawalStatus] = useState('');

      const handleClose2 = () => {
        setOpen2(false);
      };
      const handleToggle2 = () => {
        setOpen2(!open2);
      };


      const { isLoading: isUpdating, mutate: updatewithdrawal } = useMutation(
        async () => {
          return await apiClient.post(`/api/update-withdrawal`, {
            withdrawal_id: withdrawalId,
            withdrawal_status: withdrawalStatus,
          });
          
        },
        {
          onSuccess: (res) => {
              if (res.data.status === 'success') {
      
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                setTimeout(() => {
                  const event = new Event('newMessage');
                  window.dispatchEvent(event);
                }, 1000);
      
                const event2 = new Event('processed');
                window.dispatchEvent(event2);                
                
              }            
          },
          onError: (err) => {
            let errorMessage = err.response?.data || err ;
            localStorage.setItem('notification', JSON.stringify({message : `${errorMessage.message}`, 'type': 'error' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event4 = new Event('processed');
            window.dispatchEvent(event4);
            
          },
        }
      );


  const getDate = (date) => new Date(date).toDateString();
  return (
    <div style={{marginTop: '75px'}}>
    <Container>
        <Typography  mt={4} mb={2} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center', textTransform: 'uppercase' }} gutterBottom variant="h6" component="div">
        
        List of Withdrawals
      </Typography>
    <TableContainer component={Paper}>
        
      {withdrawals !== null &&
      <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
        
        <TableCell>User Name</TableCell>
          <TableCell align="right">User Email</TableCell>
          <TableCell align="right">Amount</TableCell>
          <TableCell align="right">Coin</TableCell>
          <TableCell align="right">Address</TableCell>

          <TableCell align="right">Status</TableCell>
          <TableCell align="right">Withdrawal Source</TableCell>
          <TableCell align="right">Edit</TableCell>
          <TableCell align="right">Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {withdrawals.map((withdrawal) => (
          <TableRow
            key={withdrawal.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
            {withdrawal.user.name}
            </TableCell>
            <TableCell align="right">{withdrawal.user.email}</TableCell>
            <TableCell align="right">{formatPrice(withdrawal.amount, withdrawal.user.currency)}</TableCell>
            <TableCell align="right">{withdrawal.coin_name}</TableCell>
            <TableCell align="right">{withdrawal.address}</TableCell>
            <TableCell align="right">{withdrawal.status}</TableCell>
            <TableCell align="right">{withdrawal.source}</TableCell>
            <TableCell align="right"><Button
            onClick={() =>{handleToggle2(); setwithdrawalId(withdrawal.id);}} 
             startIcon={<EditIcon />}>Edit withdrawal</Button></TableCell>
            <TableCell align="right">{getDate(withdrawal.created_at)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      }
      
    </TableContainer>
    </Container>

    <Dialog
        
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{textAlign: 'center'}}>{"UPDATE STATUS"}</DialogTitle>
        <DialogContent>
          <Box px={1} sx={{display: 'flex', justifyContent: 'space-evenly'}}>

          <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
            <InputLabel htmlFor="status">status</InputLabel>
                <Select
                    onChange={(e) => setwithdrawalStatus(e.target.value)}
                    labelId="status"
                    id="status"
                    name="status"
                    label='status'
                    >
                    <MenuItem selected value=''>
                        select status
                    </MenuItem> 
                    <MenuItem value="pending">pending</MenuItem>
                    <MenuItem value="approved">Approved</MenuItem>
                    <MenuItem value="declined">Declined</MenuItem>
                </Select>
            </FormControl>
          </Box>          
        </DialogContent>
        
        <DialogActions>
            <Button onClick={()=>updatewithdrawal()} color='error'>{isUpdating? 'Updating...': 'Update Status'}</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}