import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from "react-query";
import { Button } from '@mui/material';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import apiClient from "../../request/http-common";

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';

import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setWithdrawals } from '../../request/Analysis/hitorySlice';

const SendCoin = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const coins = useSelector((state) => state.coins.coins);
    const dispatch = useDispatch();
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
    const addresses = useSelector((state) => state.coins.addresses);

    const fundingBal = useSelector((state) => state.wallet.fundingBalance);
    const subBal = useSelector((state) => state.wallet.subscriptionBalance);
    const refBal = useSelector((state) => state.wallet.referralBalance);
    const mainBal = useSelector((state) => state.wallet.withdrawableBalance);

 const coinRates = useSelector((state) => state.coins.coinRates);
    const [error, setErros] = useState('');
    const [amount, setAmount] =useState(0);
    const [coinName, setCoinName] =useState('');
    const [address, setAddress] =useState('');
    const [source, setSource] =useState('');

    const fundings = useSelector((state) => state.wallet.fundings);
    const tradings = useSelector((state) => state.history.tradings);
  const investments = useSelector((state) => state.history.investments);
  const withdrawals = useSelector((state) => state.history.withdrawals);

    // const calculateBalance = (coin) => {
    //   let amount = 0;
    //   fundings.forEach(fund => {
    //     if (fund.gateway === coin) {
    //       amount += parseFloat(fund.amount);
    //     }
    //     });
      
    //     return amount;
    // }
    
    
    const calculateBalance = (coin) => {
      let amount = 0;

      fundings.forEach(fund => {
        if (fund.gateway === coin) {
          amount += parseFloat(fund.amount);
        }
        });

        tradings.forEach(trade => {
          if (trade.pair === coin) {
            amount += parseFloat(trade.profit);
          }
          });

          investments.forEach(investment => {
            if (investment.payment_source === coin && investment.status != 'declined') {
              amount -= parseFloat(investment.amount_invested);
            }
            });

            withdrawals.forEach(withdrawal => {
              
              if (withdrawal.source === coin && withdrawal.status != 'declined') {
                amount -= parseFloat(withdrawal.amount);
              }
              });
      
        return amount;
    }

    const { isLoading: isWithdrawing, mutate: postWithdrawal } = useMutation(
        async () => {
          return await apiClient.post(`/api/withdraw`, {
            amount: amount,
            coin_name: coinName,
            address: address, 
            source: source

          });
        },
        {
          onSuccess: (res) => {
              if (res.data.status === 'success') {
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
                dispatch(setWithdrawals(res.data.withdrawals))
                setTimeout(() => {
                  navigate('/withdrawals')
                }, 2000);
              }            
          },
          onError: (err) => {
                if (err.response?.data.length) {
                    let myerror = err.response?.data || err;         
                setErros(myerror.errors) 
                
                }else{
                    let errorMessage = err.response?.data.message || err ;
                    localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                    const event = new Event('newMessage');
                    window.dispatchEvent(event);
                }
                

                
          },
        }
      );

  return (
    <div style={{marginTop: '70px'}}>
      <Card className='box' sx={{ borderRadius: '9px', height: '100%', bgcolor: '#36492d', margin: 2}}>
    <Typography my={3} px={4} sx={{textAlign: 'center'}}  variant="body2" color="white">
    Fill the form below to withdraw funds
    </Typography>

    <CardContent>

      <FormControl fullWidth={true} sx={{ mx: 0, minWidth: 50, marginBottom: 2 }}>
      <InputLabel htmlFor="amount">Amount(USD)</InputLabel>
      {(error !== '' && error.amount) ?

      <OutlinedInput
      onChange={(e)=>setAmount(e.target.value)}
      error
      id="amount"
      type={'number'}
      name="amount"
      label={`Amount (USD)`}
      helperText={error.amount}
      />
      :
      <OutlinedInput
      onChange={(e)=>setAmount(e.target.value)}
      id="amount"
      type={'number'}
      name="amount"
      label={`Amount (USD)`}
      />
      }
      <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
      {error !== '' && error.amount}
      </Typography>
  </FormControl>

  {addresses.length > 0 &&
  <FormControl fullWidth={true} sx={{ m: 0, minWidth: 50, marginBottom: 2 }}>
  <InputLabel htmlFor="coinName">Select Coin</InputLabel>
  {(error !== '' && error.coinName) ?
      <Select
          error
          onChange={(e)=>setCoinName(e.target.value)}
          labelId="coinName"
          id="coinName"
          name="coinName"
          label={`Select Coin`}
          helperText={error.coinName}
          >
          <MenuItem selected value=''>
              Select Coin
          </MenuItem> 
          {addresses.map(coin =>(
              <MenuItem key={coin.id} value={coin.coin_name}>{coin.coin_name}</MenuItem>
          ))}             
          
      </Select>
  :
      <Select
          onChange={(e)=>setCoinName(e.target.value)}
          labelId="coinName"
          name="coinName"
          id="coinName"
          label={`Select Coin`}
          >
          <MenuItem selected value="">
              Select Coin
          </MenuItem>              
          {addresses.map(coin =>(
          <MenuItem key={coin.id} value={coin.coin_name}>{coin.coin_name}</MenuItem>
          ))} 
      </Select>
  }
  <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
  {error !== '' && error.coinName}
  </Typography>
</FormControl>
  }


<FormControl fullWidth={true} sx={{ m: 0, minWidth: 50, marginBottom: 2 }}>
  <InputLabel htmlFor="source">Source</InputLabel>
  {(error !== '' && error.source) ?
      <Select
          error
          onChange={(e)=>setSource(e.target.value)}
          labelId="source"
          id="source"
          name="source"
          label={`Source`}
          helperText={error.source}
          >
          <MenuItem selected value=''>
              Source
          </MenuItem> 
          <MenuItem selected value="Overall Asset">
          Overall Asset (Bal = {formatPrice(fundingBal)})
          </MenuItem>
          {/* {coinRates.length > 0 && 
            coinRates.map((coin) =>(
              <MenuItem key={coin.symbol} selected value={coin.symbol}>
              {coin.symbol}
              </MenuItem> 
            ))}             */}
          
      </Select>
  :
      <Select
          onChange={(e)=>setSource(e.target.value)}
          labelId="source"
          name="source"
          id="source"
          label={`Source`}
          >
          <MenuItem selected value="">
              Source
          </MenuItem>   
          <MenuItem selected value="Overall Asset">
          Overall Asset (Bal = {formatPrice(fundingBal)})
          </MenuItem>

          {/* {coinRates.length > 0 && 
            coinRates.map((coin) =>(
              <MenuItem key={coin.symbol} selected value={coin.symbol}>
              {coin.symbol} ({coin.name}) (bal = {formatPrice(calculateBalance(coin.symbol))})
              </MenuItem> 
            ))}  */}
      </Select>
  }
  <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
  {error !== '' && error.source}
  </Typography>
</FormControl>

<FormControl fullWidth={true} sx={{ mx: 0, minWidth: 50, marginBottom: 2 }}>
      <InputLabel htmlFor="address">Wallet Address</InputLabel>
      {(error !== '' && error.address) ?

      <OutlinedInput
      onChange={(e)=>setAddress(e.target.value)}
      error
      id="address"
      type={'text'}
      name="address"
      label="Wallet Address"
      helperText={error.address}
      />
      :
      <OutlinedInput
      onChange={(e)=>setAddress(e.target.value)}
      id="address"
      type={'text'}
      name="address"
      label="Wallet Address"
      />
      }
      <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
      {error !== '' && error.address}
      </Typography>
  </FormControl>
  </CardContent>

    <CardActions>
    <Button onClick={postWithdrawal} fullWidth={true} size="large" color="primary" variant="contained">{isWithdrawing ? 'Processing....': 'Withdraw'}</Button>
    </CardActions>

  </Card>
    </div>
  )
}

export default SendCoin
