import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { Link as RouterLink } from 'react-router-dom';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TradingButton from '../components/Trader/TradingButton';
import { useLocation } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';


export default function Footer() {
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);
  const location = useLocation();
  const { pathname } = location;

  return (
    <Box sx={{ display: {md: 'none'},  pb: 7 }} ref={ref}>
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0,   }} elevation={3}>
      {pathname === '/trade' ? 
         <TradingButton/>
        : 
        <BottomNavigation
        // 36492d
        // bce70c
        sx={{bgcolor: '#36492d'}}
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction component={RouterLink} to="/" label="Dashboard" icon={
             <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730471754/layout_holcz2.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
            </Avatar>  
          } />
          <BottomNavigationAction component={RouterLink} to="/account-wallet" label="Wallet" icon={
            <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730472128/wallet_ldkmyq.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
          </Avatar> } />

          <BottomNavigationAction component={RouterLink} to="/receive" label="Deposit" icon={
            <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730472131/deposit_ku98mv.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
            </Avatar> 
            } />
          <BottomNavigationAction component={RouterLink} to="/withdrawals" label="Withdraw" icon={
            <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730471835/atm_xmfof9.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
            </Avatar> 
          } />
          
          {/* <BottomNavigationAction label="Our Menu" icon={<MenuBookIcon />}/> */}
          
        </BottomNavigation>
        }
      </Paper>
    </Box>
  );
}
