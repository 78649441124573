import React, {useEffect, useState} from 'react'
import apiClient from "../../request/http-common";

import Card from '@mui/material/Card';
import { CardActions, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';

import { useMutation } from "react-query";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import { setTradings } from '../../request/Analysis/hitorySlice';

const TradingButton = () => {
  
    // start drawer code
    const [barstate, setBarState] = useState({
        top: false,
        bottom: false,
        bottom: false,
        right: false,
      });
    
      const togglebottomBar = () => {
        const event = new Event("toggleWithdrawalBar");
        window.dispatchEvent(event);
      }
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (
          event &&
          event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')
        ) {
          return;
        }
        setBarState({ ...barstate, [anchor]: open });
      };
      // end drawer code

      // start trading code
      const [error, setErros] = useState('');
      const appMode = useSelector((state) => state.mode.mode);
      const currentPair = useSelector((state) => state.currentPair.currentPair);
      const coinRates = useSelector((state) => state.coins.coinRates);
      const tradingPlans = useSelector((state) => state.plans.tradingPlans);

      const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
      const initwallet = {
          start_time : '',
          duration : '',
          stake : '', 
          stop_loss: '',
          take_profit: '',
      }
  
      const [walletForm, setwalletForm] = useState(initwallet);
      const onChange = (e) =>
      setwalletForm({ ...walletForm, [e.target.name]: e.target.value });
  
      
    const [coinDetails, setCoinDetails ] = useState(null);
    const calculate24hrChange = (coin) => {
      let pair = {};
      coinRates.forEach(coinRater => {
        if (coinRater.symbol === coin) {
          pair = coinRater;
        }
      });
    return pair;
    }

    const [amount, setAmount] = useState(0);  
    const navigate = useNavigate();
    const mainBal = useSelector((state) => state.wallet.withdrawableBalance);
    const dispatch = useDispatch();

    const [selectedPlan, setSelectedPlan] = useState('');
    const [source, setSource] = useState('');

    const setPlanDetails = (planId) =>{
      tradingPlans.forEach(plan => {
        if (parseInt(plan.id) === parseInt(planId)) {
          setSelectedPlan(plan);
          setSource(planId);
        }
      });
    }

    const [orderType, setOrderType] = useState('up');

    const { isLoading: isInvesting, mutate: postInvestment } = useMutation(
      async () => {
        return await apiClient.post(`/api/start-trading`, {
          timer_units: source,
          amount: amount, 
          duration: walletForm.duration,
          stop_loss: walletForm.stop_loss,
          take_profit: walletForm.take_profit,
          trading_pair: currentPair,
          order_type: orderType,
          pair: currentPair,
          entry_point: parseFloat(coinDetails.priceUsd).toFixed(6)

        });
      },
      {
        onSuccess: (res) => {
            if (res.data.status === 'success') {
              dispatch(setTradings(res.data.tradings));
              localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
              const event = new Event('newMessage');
              window.dispatchEvent(event);
              setTimeout(() => {
                navigate('/trade-history');
              }, 2000);
            }            
        },
        onError: (err) => {
              if (err.response?.data.length) {
                let myerror = err.response?.data || err;         
            setErros(myerror.errors)               
            }else{
                let errorMessage = err.response?.data.message || err ;
                localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
            }
        },
      }
    );
      
      // end trading code

    useEffect(() => {
      setCoinDetails(calculate24hrChange(currentPair))     
        window.addEventListener(
          "toggleWithdrawalBar",
          (e) => {
           setBarState({ ...barstate, ['bottom']: true });
          },
          false
        );
      }, []);
      
  return (
    <div>
      <Card px={2}  sx={{borderRadius: '9px', height: '100%', bgcolor: '#36492d'}}>
         <CardActions sx={{justifyContent: 'space-between'}}>
             <Button onClick={()=>togglebottomBar()} fullWidth={true} variant="contained" color='success' startIcon={<ArrowUpwardIcon />}>
                 Up
             </Button>
             <Button onClick={()=>togglebottomBar()} fullWidth={true} variant="contained" color='secondary' startIcon={<ArrowDownwardIcon />}>
                 Down
             </Button>
         </CardActions>
     </Card>     

     <SwipeableDrawer
                sx={{bgcolor: '#36492d'}}
                anchor={`bottom`}
                open={barstate[`bottom`]}
                onClose={toggleDrawer(`bottom`, false)}
                onOpen={toggleDrawer(`bottom`, true)}> 

                <Card sx={{bgcolor: '#36492d', color: 'white'}}>

                    <CardContent justifyContent="center">

                    <Box mt={4} sx={{display: 'flex', justifyContent: 'space-between'}}>
                       
                      <FormControl fullWidth={true} sx={{mt: 1, minWidth: 50 }}>
                          <InputLabel htmlFor="time">Timer Units</InputLabel>
                          {(error !== '' && error.time) ?
                          <Select
                          error
                          onChange={(e) => setPlanDetails(e.target.value)}
                          labelId="time"
                          id="time"
                          name="time"
                          label={`Timer Units`}
                          helperText={error.time}
                          >
                          <MenuItem selected value=''>
                          Select Option
                          </MenuItem> 
                          {tradingPlans.length > 0 && tradingPlans.map(plan =>(
                            <MenuItem selected value={plan.id}
                            >
                            {plan.duration}
                            </MenuItem>
                          ))}
                      </Select>
                        :
                        <Select
                            onChange={(e) => setPlanDetails(e.target.value)}
                            labelId="time"
                            name="time"
                            id="time"
                            label={`Timer Units`}
                            >
                            <MenuItem selected value=''>
                            Seconds
                            </MenuItem> 

                            {tradingPlans.length > 0 && tradingPlans.map(plan =>(
                            <MenuItem selected value={plan.id}>
                            {plan.duration}
                            </MenuItem>
                          ))}
                        </Select>
                        }
                        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        {error !== '' && error.time}
                        </Typography>
                    </FormControl>

                    <FormControl  fullWidth={true} sx={{marginLeft: '5px',  mt: 1, minWidth: 50 }}>
                        <InputLabel htmlFor="duration">Duration</InputLabel>
                        {(error !== '' && error.duration) ?

                        <OutlinedInput
                        onChange={onChange}
                        error
                        id="duration"
                        type={'text'}
                        name="duration"
                        label="Duration"
                        helperText={error.duration}
                        />
                        :
                        <OutlinedInput
                        onChange={onChange}
                        id="duration"
                        type={'text'}
                        name="duration"
                        label="Duration"
                        />
                        }
                        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        {error !== '' && error.duration}
                        </Typography>
                      </FormControl>
                    </Box>

                        {(selectedPlan !== '') &&
                        <Alert severity="success">Gain: {selectedPlan.min_range}% ~ {selectedPlan.max_range}%
                        </Alert>
                        }

                        <FormControl  fullWidth={true} sx={{ mt: 1, mb: 1, minWidth: 50 }}>
                        <InputLabel htmlFor="amount">Stake (USDT)</InputLabel>
                        <OutlinedInput
                          onChange={(e)=>setAmount(e.target.value)}
                          id="amount"
                          type={'number'}
                          label="Stake (USDT)"
                        />
                      </FormControl>
                        {selectedPlan !== '' &&
                        <Alert severity="info">
                          <Typography>
                          Min Stake: {formatPrice(selectedPlan.min_amount)}
                          </Typography>
                          <Typography>
                          Max Stake: {formatPrice(selectedPlan.max_amount)} 
                          </Typography>                         
                        </Alert>
                        }

                    
                    

                  <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <FormControl  fullWidth={true} sx={{ mt: 1, minWidth: 50 }}>
                      <InputLabel htmlFor="take_profit">Take Profit (optional)</InputLabel>
                      {(error !== '' && error.stake) ?

                      <OutlinedInput
                      onChange={onChange}
                      error
                      id="take_profit"
                      type={'text'}
                      name="take_profit"
                      label="Take Profit (optional)"
                      helperText={error.take_profit}
                      />
                      :
                      <OutlinedInput
                      onChange={onChange}
                      id="take_profit"
                      type={'text'}
                      name="take_profit"
                      label="Take Profit (optional)"
                      />
                      }
                      <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                      {error !== '' && error.take_profit}
                      </Typography>
                  </FormControl>  

                  <FormControl fullWidth={true} sx={{marginLeft: '5px', mt: 1, minWidth: 50 }}>
                      <InputLabel htmlFor="stop_loss">Stop Loss (optional)</InputLabel>
                      {(error !== '' && error.stake) ?

                      <OutlinedInput
                      onChange={onChange}
                      error
                      id="stop_loss"
                      type={'text'}
                      name="stop_loss"
                      label="Stop Loss (optional)"
                      helperText={error.stop_loss}
                      />
                      :
                      <OutlinedInput
                      onChange={onChange}
                      id="stop_loss"
                      type={'text'}
                      name="stop_loss"
                      label="Stop Loss (optional)"
                      />
                      }
                      <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                      {error !== '' && error.stop_loss}
                      </Typography>
                  </FormControl> 
                  </Box> 

                      {(selectedPlan !== '' && amount !== 0) &&
                        <Alert severity="success">Potential Profit:  {formatPrice((selectedPlan.min_range/100) * amount)} ~ {formatPrice((selectedPlan.max_range/100) * amount)} 
                        </Alert>
                        }
                    </CardContent>

                    <CardActions>
                      <Button onClick={postInvestment} fullWidth={true} size="large" color="primary" variant="contained"> 
                      {isInvesting ? 'confirming...' : 'Confirm Order'}  
                      </Button> 
                    </CardActions>
                </Card> 

                     
              </SwipeableDrawer>
    </div>
  )
}

export default TradingButton
