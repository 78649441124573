import * as React from 'react';
import { Fragment } from 'react';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import TelegramIcon from '@mui/icons-material/Telegram';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import GradingIcon from '@mui/icons-material/Grading';
import RuleIcon from '@mui/icons-material/Rule';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TimelineIcon from '@mui/icons-material/Timeline';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AddchartIcon from '@mui/icons-material/Addchart';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
// import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import Avatar from '@mui/material/Avatar';

export default function Online() {
  return (
    
    <Fragment>
          <List>
            <Link  to='/' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730471754/layout_holcz2.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                  </Avatar> 
                  </ListItemIcon>
                  <ListItemText  primary={`Dashboard`} />
                  </ListItemButton>
              </ListItem>
            </Link>

           

            <Link  to='/receive' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730472131/deposit_ku98mv.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                  </Avatar> 
                  {/* <CurrencyExchangeIcon /> */}
                  </ListItemIcon>
                  <ListItemText  primary={`Deposit`} />
                  </ListItemButton>
              </ListItem>
            </Link>


              
             
              <Link  to='/copy-plans' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730853521/trading_1_yqi9qp.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                    </Avatar>
                    {/* <AddchartIcon /> */}
                    </ListItemIcon>
                    <ListItemText  primary={`Insider Package`} />
                    </ListItemButton>
                </ListItem>
              </Link>

              <Link  to='/copy-history' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730472111/market_xjp97v.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                  </Avatar>
                  {/* <CandlestickChartIcon /> */}
                  </ListItemIcon>
                  <ListItemText  primary={`Insider History`} />
                  </ListItemButton>
              </ListItem>
            </Link>

              
              

            <Link  to='/package/Metals' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    {/* <AutoAwesomeIcon /> */}
                    <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730438744/ingots_nlhuht.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                      {/* <ArrowOutwardIcon /> */}
                    </Avatar>
                    </ListItemIcon>
                    <ListItemText  primary={`Metals Reserve`} />
                    </ListItemButton>
                </ListItem>
              </Link>

              <Link  to='/package/Currency' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730438742/exchange_m7e5ft.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                  </Avatar>
                  {/* <AutoGraphIcon /> */}
                  </ListItemIcon>
                  <ListItemText  primary={`Foreign Exchange Investment`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/package/Couples Package' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730438701/romantic_1_zv1gtq.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                  </Avatar>
                  {/* <StackedBarChartIcon /> */}
                  </ListItemIcon>
                  <ListItemText  primary={`Couples Package`} />
                  </ListItemButton>
              </ListItem>
            </Link>



            <Link  to='/package/Crypto Trading' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                    <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730439334/online-trading_1_wgatzs.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                    {/* <AccountBalanceWalletIcon /> */}
                  </Avatar>
                  {/* <StackedBarChartIcon /> */}
                  </ListItemIcon>
                  <ListItemText  primary={`Crypto Asset Holdings`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            
            <Link  to='/start-trading' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730472104/histogram_yfewnl.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                    </Avatar>
                    {/* <AddchartIcon /> */}
                    </ListItemIcon>
                    <ListItemText  primary={`Real-Time Market Investment`} />
                    </ListItemButton>
                </ListItem>
              </Link>

            <Link  to='/trade-history' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730472111/market_xjp97v.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                  </Avatar>
                  {/* <CandlestickChartIcon /> */}
                  </ListItemIcon>
                  <ListItemText  primary={`Real-Time Market History`} />
                  </ListItemButton>
              </ListItem>
            </Link>
            

            <Link  to='/account-wallet' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730472128/wallet_ldkmyq.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                    </Avatar>
                    {/* <AccountBalanceWalletIcon /> */}
                    </ListItemIcon>
                    <ListItemText  primary={`Wallet`} />
                    </ListItemButton>
                </ListItem>
              </Link>

              {/* <Link  to='/spot-transfer' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730472116/money_vn6pjz.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                    </Avatar>
                    </ListItemIcon>
                    <ListItemText  primary={`Transfer`} />
                    </ListItemButton>
                </ListItem>
              </Link> */}

            <Link  to='/send-coin' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730472135/send_hnrw88.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                  </Avatar> 
                  </ListItemIcon>
                  <ListItemText  primary={`Withdraw`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            

              <Link  to='/investments' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730471764/candlestick-chart_m02ulg.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                  </Avatar> 
                  {/* <StackedBarChartIcon /> */}
                  </ListItemIcon>
                  <ListItemText  primary={`Investment History`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link  to='/swap-coin' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730472116/money_vn6pjz.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                    {/* <AccountBalanceWalletIcon /> */}
                  </Avatar>
                    {/* <SwapHorizIcon /> */}
                    </ListItemIcon>
                    <ListItemText  primary={`Swap`} />
                    </ListItemButton>
                </ListItem>
              </Link>

            <Link  to='/referrals' component={RouterLink} underline="none" color="inherit">
                <ListItem  disablePadding>
                    <ListItemButton>
                    <ListItemIcon>
                    <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730473153/employee_xnjh0n.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                    {/* <AccountBalanceWalletIcon /> */}
                  </Avatar>
                    {/* <Diversity3Icon /> */}
                    </ListItemIcon>
                    <ListItemText  primary={`Referral`} />
                    </ListItemButton>
                </ListItem>
              </Link>

              


             

            <Link  to='/transactions' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730471764/candlestick-chart_m02ulg.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                    {/* <AccountBalanceWalletIcon /> */}
                  </Avatar>
                  {/* <TimelineIcon /> */}
                  </ListItemIcon>
                  <ListItemText  primary={`Transactions`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            

            

            <Link  to='/cards' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730472096/debit-card_ck8cgx.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                    {/* <AccountBalanceWalletIcon /> */}
                  </Avatar>
                  {/* <CreditCardIcon /> */}
                  </ListItemIcon>
                  <ListItemText  primary={`Cards`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            <Link to='/withdrawals' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730471835/atm_xmfof9.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                    {/* <AccountBalanceWalletIcon /> */}
                  </Avatar>
                  {/* <CoPresentIcon /> */}
                  </ListItemIcon>
                  <ListItemText  primary={`Withdrawals`} />
                  </ListItemButton>
              </ListItem>
            </Link>

            {/* <Link  to='/loans' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <RuleIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Loans`} />
                  </ListItemButton>
              </ListItem>
            </Link> */}
                     
          </List>
          <List>
            
            <Divider/>
            {/* <Link  to='https://tawk.to/chat/642872214247f20fefe94604/1gsuvrltc'  component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <HelpCenterIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Support`} />
                </ListItemButton>
            </ListItem>
          </Link> */}

          {/* <Link  href='' underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <TelegramIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Telegram`} />
                </ListItemButton>
            </ListItem>
          </Link> */}

          {/* <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <EmailIcon/>
                </ListItemIcon>
                <ListItemText  primary={`Email Contact`} />
                </ListItemButton>
            </ListItem>
          </Link> */}

          <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
            <ListItem  disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730471813/settings_tynzph.png' sx={{ padding: '5px', width: 30, height: 30, bgcolor: '#8aa80c', color: '#fff'}}>
                    {/* <AccountBalanceWalletIcon /> */}
                  </Avatar>
                {/* <SettingsIcon/> */}
                </ListItemIcon>
                <ListItemText  primary={`Settings`} />
                </ListItemButton>
            </ListItem>
          </Link>
    </List>
      
    </Fragment>
  );
}