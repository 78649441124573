import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const EditInvestment = () => {

    const params = useParams();
    // const [investment, setinvestment] = useState(null);
    const investment = useSelector((state) => state.history.current_investments);

    const [investmentPlans, setInvestmentPlans] = useState(null);

    const { isLoading: isLoadinginvestments, refetch: getinvestments } = useQuery(
        "investment-plans",
        async () => {
          return await apiClient.get(`/api/investment-plans`);
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setInvestmentPlans(res.data.plans);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      
    const dispatch = useDispatch();
    
    const initialLogin = {
        amount_invested : investment.amount_invested	,
        payment_source : investment.payment_source	,
        status : investment.status,
        earning : investment.earning	,
        duration : investment.duration	,
        investment_plan_id : investment.investment_plan_id	,

    }
    
    
    const [error, setErros] = useState('');
    const [investmentForm, setinvestmentForm] = useState(initialLogin);

    const onChange = (e) =>
    setinvestmentForm({ ...investmentForm, [e.target.name]: e.target.value });


    const { isLoading: isSendingRequest, mutate: postinvestment } = useMutation(

        async () => {
          return await apiClient.post(`/api/edit-investment/${params.id}`, {
            amount : investmentForm.amount_invested,	
            payment_source : investmentForm.payment_source,	
            status : investmentForm.status,	
            earning : investmentForm.earning,	
            duration : investmentForm.duration,	
            investment_plan_id : investmentForm.investment_plan_id,	

          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.errors)  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);     
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postinvestment();
      } 

      useEffect(() => {
        getinvestments();
      }, []);

      console.log(investment)
  return (
    <div style={{marginTop: '70px'}}>
        {investment !== null && 

        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        EDIT INVESTMENT
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to edit investment
                    </Typography>
                        
                        
                      <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
                          <InputLabel htmlFor="payment_source">Select Payment Source</InputLabel>
                          {(error !== '' && error.duration) ?
                          <Select
                          error
                          onChange={onChange}
                          labelId="payment_source"
                          id="payment_source"
                          defaultValue={investment.payment_source}
                          name="payment_source"
                          label={`Select Payment Source`}
                          helperText={error.duration}
                          >
                          <MenuItem selected value=''>
                          Select Gateway
                          </MenuItem> 

                          <MenuItem selected value="Overall Asset">
                          Overall Asset
                          </MenuItem>

                          <MenuItem selected value="Accumulated Profit">
                          Accumulated Profit
                          </MenuItem>
            
                          
                      </Select>
                          :
                          <Select
                              onChange={onChange}
                              labelId="payment_source"
                              name="payment_source"
                              id="payment_source"
                              defaultValue={investment.payment_source}
                              label={`Select Payment Source`}
                              >
                              <MenuItem selected value=''>
                              Select Gateway
                              </MenuItem> 

                              <MenuItem selected value="Overall Asset">
                              Overall Asset
                              </MenuItem>

                              <MenuItem selected value="Accumulated Profit">
                              Accumulated Profit
                              </MenuItem>
                          </Select>
                          }
                          <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                          {error !== '' && error.duration}
                          </Typography>
                      </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="amount_invested">Amount Invested</InputLabel>
                            {(error !== '' && error.amount_invested) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="amount_invested"
                            defaultValue={investment.amount_invested}
                            type={'number'}
                            name="amount_invested"
                            label="Amount Invested"
                            helperText={error.amount_invested}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="amount_invested"
                            defaultValue={investment.amount_invested}
                            type={'number'}
                            name="amount_invested"
                            label="Amount Invested"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.amount_invested}
                            </Typography>
                        </FormControl>


                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="earning">Earning</InputLabel>
                            {(error !== '' && error.earning) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            defaultValue={investment.earning}
                            id="earning"
                            type={'number'}
                            name="earning"
                            label="Earning"
                            helperText={error.earning}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="earning"
                            type={'number'}
                            defaultValue={investment.earning}
                            name="earning"
                            label="Earning"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.earning}
                            </Typography>
                            
                        </FormControl>

                        

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="duration">Duration</InputLabel>
                            {(error !== '' && error.duration) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="duration"
                            defaultValue={investment.duration}
                            type={'text'}
                            name="duration"
                            label="Duration"
                            helperText={error.duration}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="duration"
                            defaultValue={investment.duration}
                            type={'text'}
                            name="duration"
                            label="Duration"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.duration}
                            </Typography>
                            
                        </FormControl>

                        {investmentPlans !== null &&
                        <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
                        <InputLabel htmlFor="investment_plan_id">Investment Plan</InputLabel>
                        {(error !== '' && error.investment_plan_id) ?
                        <Select
                        error
                        onChange={onChange}
                        defaultValue={investment.investment_plan_id}
                        labelId="investment_plan_id"
                        id="investment_plan_id"
                        name="investment_plan_id"
                        label={`Investment Plan`}
                        helperText={error.investment_plan_id}
                        >
                        <MenuItem selected value=''>
                            Investment Plan
                        </MenuItem> 
                        {investmentPlans.map(plan =>(
                            <MenuItem value={plan.id}>{plan.name}</MenuItem>
                        ))}             
                        
                    </Select>
                        :
                        <Select
                            onChange={onChange}
                            labelId="investment_plan_id"
                            name="investment_plan_id"
                            id="investment_plan_id"
                            defaultValue={investment.investment_plan_id}
                            label={`Investment Plan`}
                            >
                            <MenuItem selected value="">
                                Investment Plan
                            </MenuItem>              
                             {investmentPlans.map(plan =>(
                            <MenuItem value={plan.id}>{plan.name}</MenuItem>
                            ))}   
                        </Select>
                        }
                        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        {error !== '' && error.investment_plan_id}
                        </Typography>
                    </FormControl>
                        }

                        {/* <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="duration">Duration</InputLabel>
                            {(error !== '' && error.duration) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={2} multiline
                            id="duration"
                            name="duration"
                            defaultValue={investment.duration}
                            type={'text'}
                            label="Duration"
                            helperText={error.duration}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={2} multiline
                            id="duration"
                            defaultValue={investment.duration}
                            name="duration"
                            type={'text'}
                            label="Duration"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.duration}
                            </Typography>
                        </FormControl> */}

<FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
            <InputLabel htmlFor="status">status</InputLabel>
                <Select
                    onChange={onChange}
                    defaultValue={investment.status}
                    labelId="status"
                    id="status"
                    name="status"
                    label='status'
                    >
                    <MenuItem selected value=''>
                        select status
                    </MenuItem> 
                    <MenuItem value="pending">pending</MenuItem>
                    <MenuItem value="approved">Approved</MenuItem>
                    <MenuItem value="declined">Declined</MenuItem>
                    <MenuItem value="completed">completed</MenuItem>
                </Select>
            </FormControl>
                        
                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Updating...' : 'Update investment'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
        }
    </div>
  )
}

export default EditInvestment
