import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';

const CreateAddress = () => {
      
    
    const initwallet = {
        name : ''	,
        address : ''	,
        
    }
    
    
    const [error, setErros] = useState('');
    const [walletForm, setwalletForm] = useState(initwallet);
    const coinRates = useSelector((state) => state.coins.coinRates);

    const onChange = (e) =>
    setwalletForm({ ...walletForm, [e.target.name]: e.target.value });


    const { isLoading: isSendingRequest, mutate: postwallet } = useMutation(

        async () => {
          return await apiClient.post(`/api/create-address`, {
            coin_name : walletForm.name,	
            address : walletForm.address,
          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.errors)  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);     
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postwallet();
      } 


  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        CREATE WALLET ADDRESSES 
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to create wallets for payment
                    </Typography>


                    <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50, marginBottom: '10px' }}>
                      <InputLabel htmlFor="name">Coin Name</InputLabel>
                      <Select
                          onChange={onChange}
                          labelId="name"
                          name="name"
                          id="name"
                          label={`Coin Name`}
                          >
                          <MenuItem selected value=''>
                          Select Coin
                          </MenuItem> 

                          {coinRates.length > 0 && 
                          coinRates.map((coin) =>(
                            <MenuItem key={coin.symbol} selected value={coin.symbol}>
                            {coin.symbol}
                            </MenuItem> 
                          ))}
                      </Select>                        
                    </FormControl>

                    <Typography variant='h4'>
                      OR
                    </Typography>

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="wallet Name">Coin Name</InputLabel>
                            {(error !== '' && error.name) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="name"
                            name="name"
                            type={'text'}
                            label="Coin Name"
                            helperText={error.name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="name"
                            name="name"
                            type={'text'}
                            label="Coin Name"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.name}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="address">Address</InputLabel>
                            {(error !== '' && error.address) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="address"
                            type={'text'}
                            name="address"
                            label="Address"
                            helperText={error.address}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="address"
                            type={'text'}
                            name="address"
                            label="Address"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.address}
                            </Typography>
                        </FormControl>                        
                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Creating...' : 'Create wallet'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </div>
  )
}

export default CreateAddress
