import React, {Fragment, useState, useEffect} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import WalletSlide from '../Wallet/WalletSlide';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Currency } from '@depay/local-currency';

import Container from '@mui/material/Container';


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import LockClockIcon from '@mui/icons-material/LockClock';
import Chip from '@mui/material/Chip';
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import TradeTimer from '../Trader/TradeTimer';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
const TradeHistory = () => {

  const appMode = useSelector((state) => state.mode.mode);

    const user = useSelector((state) => state.user.user);
    const tradings = useSelector((state) => state.history.tradings);
    const [rate, setRate] = useState(1);

    const callRate = async()=> {
      await Currency.fromUSD({ amount: 1 }).then(amount=>{
        setRate(amount.amount);
       });
    
  };

  // const getDate = (date) =>new Date(date).toLocaleString('en-GB', {
  //   hour12: false,
  // });

  function getDate(UNIX_timestamp){
    var a = new Date(UNIX_timestamp);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    return time;
  }

  function timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    return time;
  }

    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

    useEffect(() => {
      callRate();
    }, []);

  return (
    <div style={{marginTop: '75px'}}>  
    <Container>   

      <Typography mt={4} px={3} sx={{ marginTop: '10px', fontWeight: 'bold', fontSize: 20, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
              TRADING HISTORY
      </Typography>

        {   tradings.length > 0 &&
        
          <Box sx={{width: '100%', bgcolor: '#36492d' }}>

            {tradings.map(trade => (
              <Accordion key={trade.id} sx={{borderRadius: '10px',bgcolor: '#36492d', marginBottom: '10px'}} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box sx={{display: 'flex',  justifyContent: 'space-between', width: '95%', alignItems: 'center'}}>
                  <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <Box mb={2} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                      
                      <Chip label={trade.order_type} sx={{fontSize: '10px'}} size="small"
                       color={trade.order_type === 'up'? 'success': 'error'}
                       /> 
                        <Typography ml={1} my={0} variant="caption" color="text.secondary">
                          {trade.end_time}
                        </Typography>
                    </Box>
                    <Typography sx={{fontWeight: 'bolder'}}>{trade.pair}/USDT</Typography>
                  </Box>
                  <TradeTimer time={trade.end_time} trade={trade}/>
                </Box>
              </AccordionSummary>

              <AccordionDetails>
                <Divider/>

                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                  <Typography py={1} variant="caption" >Stake:</Typography>
                  
                  <Typography py={1} variant="caption" >{formatPrice(trade.amount)}</Typography>
                </Box>
                <Divider/>

                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                  <Typography py={1} variant="caption" >Open Time</Typography>
                  <Typography py={1} variant="caption" >{getDate(trade.created_at)}</Typography>
                </Box>
                <Divider/>

                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                  <Typography py={1} variant="caption" >Close Time</Typography>
                  
                  <Typography py={1} variant="caption" >{timeConverter(trade.end_time)}</Typography>
                </Box>
                <Divider/>

                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                  <Typography py={1} variant="caption" >Entry Point:</Typography>
                  
                  <Typography py={1} variant="caption" >{trade.entry_point}</Typography>
                </Box>
                <Divider/>

                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                  <Typography py={1} variant="caption" >Take Profit:</Typography>
                  
                  <Typography py={1} variant="caption" >{formatPrice(trade.take_profit)}</Typography>
                </Box>
                <Divider/>

                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                  <Typography py={1} variant="caption" >Stop Loss:</Typography>
                  
                  <Typography py={1} variant="caption" >{formatPrice(trade.stop_loss)}</Typography>
                </Box>
                <Divider/>

                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                  <Typography py={1} variant="caption" >Proft/Loss:</Typography>
                  
                  <Typography py={1} variant="caption" >{formatPrice(trade.profit)}</Typography>
                </Box>
                <Divider/>

                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center'}}>
                  <Typography py={1} variant="caption" >Status:</Typography>
                  
                  <Typography py={1} variant="caption" >{trade.status}</Typography>
                </Box>
                <Divider/>


                
                    <AdvancedRealTimeChart
                      symbol= {`BINANCE:${trade.pair}USDT`}
                      interval= "1"
                      timezone= "Etc/UTC"
                      theme= {appMode}
                      locale= "en"
                      hide_top_toolbar= {true}
                      enable_publishing= {false}
                      save_image={false}
                      hide_side_toolbar={true}
                      container_id= {trade.id}
                      isTransparent = {true}
                      height={400}
                      width="100%"
                      backgroundColor={appMode === 'dark' ? '#36492d' : '#ffffff'}
                    >
                     </AdvancedRealTimeChart>

                     {appMode === 'dark'? 
                          <Box sx={{height: '20px', backgroundColor: '#36492d', transform: 'translate(0px, -25px)', position: 'relative', zIndex: 1}}>
                          
                          </Box>
                          :
                          <Box sx={{height: '20px', backgroundColor: 'white', transform: 'translate(0px, -25px)', position: 'relative', zIndex: 1}}>
                          
                          </Box>
                          }
                          <Avatar sx={{display: 'absolute', zIndex: 10, transform: 'translate(10px, -170px)'}} alt="Payment icon" src="https://res.cloudinary.com/dg8pxsdme/image/upload/v1705500463/trustutilsdefi500_zqpbh7.png" />

                     {/* {appMode === 'dark'? 
                <Box sx={{height: '20px', backgroundColor: 'black', transform: 'translate(0px, -25px)', position: 'relative', zIndex: 10}}>
                
                </Box>
                :
                <Box sx={{height: '20px', backgroundColor: 'white', transform: 'translate(0px, -25px)', position: 'relative', zIndex: 10}}>
                
                </Box>
                } */}
              
              </AccordionDetails>
            </Accordion>
            ))}
          </Box>
        }
      </Container> 
    </div>
  )
}

export default TradeHistory
