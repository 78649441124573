import React from 'react'
import CoinMarket from '../Home/CoinMarket'
import WalletCards from './WalletCards'

import WalletButton from '../Wallet/WalletButton';
import WithdrawalButton from '../Withdrawal/WithdrawalButton';
import EarningAnalysis from '../Earning/EarningAnalysis';
import BalanceCard from '../Cards/BalanceCard';
import { CardActions, Card } from '@mui/material';
const NewWallet = () => {
  return (
    <div>
     <BalanceCard/>
      <Card>
        <CardActions sx={{justifyContent: 'space-between', paddingX: '15px', bgcolor: '#36492d'}}>

        <WalletButton/>        

        <WithdrawalButton/>

        </CardActions>
      </Card>

      {/* <EarningAnalysis/> */}
    
    <WalletCards/>
    </div>
  )
}

export default NewWallet
