import * as React from 'react';
import { green, pink } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import FolderIcon from '@mui/icons-material/Folder';
import PageviewIcon from '@mui/icons-material/Pageview';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

export default function MobileMenu() {
  return (
     
    <Box sx={{paddingX: '15px', marginTop: '75px'}}>
      
    <Stack mt={2} mb={4} sx={{ marginTop: '20px'}} direction="row" spacing={4} justifyContent="center" alignItems="center">

      <Box component={RouterLink}  to='/package/Metals' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
      <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730438744/ingots_nlhuht.png' sx={{ padding: '5px', width: 40, height: 40, bgcolor: '#8aa80c', color: '#fff'}}>
        {/* <ArrowOutwardIcon /> */}
      </Avatar>  
      <Typography sx={{textAlign: 'center', fontSize: '12px'}}>Metals</Typography>   
      </Box>

      <Box component={RouterLink} to='/package/Currency' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
      <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730438742/exchange_m7e5ft.png' sx={{ padding: '5px', width: 40, height: 40, bgcolor: '#8aa80c', color: '#fff'}}>
        {/* <CallReceivedIcon /> */}
      </Avatar>
      <Typography sx={{textAlign: 'center', fontSize: '12px'}}>Currency</Typography> 
      </Box>

      <Box component={RouterLink} to='/package/Crypto Trading' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit'  }}>
      <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730438716/bitcoin_d0xpro.png' sx={{ padding: '5px', width: 40, height: 40, bgcolor: '#8aa80c', color: '#fff'}}>
        {/* <StackedBarChartIcon /> */}
      </Avatar>
      <Typography sx={{textAlign: 'center', fontSize: '12px'}}>Crypto</Typography> 
      </Box>

      <Box component={RouterLink} to='/package/Couples Package' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
      <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730438701/romantic_1_zv1gtq.png' sx={{ padding: '5px', width: 40, height: 40, bgcolor: '#8aa80c', color: '#fff'}}>
        {/* <AccountBalanceWalletIcon /> */}
      </Avatar>
      <Typography sx={{textAlign: 'center', fontSize: '12px'}}>Couples</Typography> 
      </Box>

      <Box component={RouterLink} to='/copy-plans' sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', borderRadius: '50%',  textDecoration: 'none', color: 'inherit' }}>
      <Avatar src='https://res.cloudinary.com/dmzqkpypw/image/upload/v1730439334/online-trading_1_wgatzs.png' sx={{ padding: '5px', width: 40, height: 40, bgcolor: '#8aa80c', color: '#fff'}}>
        <AccountBalanceWalletIcon />
      </Avatar>
      <Typography sx={{textAlign: 'center', fontSize: '12px'}}>Insider</Typography> 
      </Box> 

       

    </Stack>
    </Box>
  );
}